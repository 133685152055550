@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}
.animate-spin-reverse-slower {
  animation: spin-reverse 6s linear infinite;
}
.animate-spin-slow {
  animation: spin 4s linear infinite;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: stonescale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-container {
  position: relative;
  overflow: hidden;
  display: block;
}

.highlighted-text:before {
  content: attr(data-text);
  display: inline-block;
  opacity: 0.8;
}

.highlighted-text {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 0;
  transition: clip-path 0.1s ease;
}

